<template>
    <view-component
    model_name="employee"
    :show_previus_days="false"></view-component>
</template>
<script>
export default {
	components: {
		ViewComponent: () => import('@/common-vue/components/view/Index')
	},
}
</script>